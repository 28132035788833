
/* eslint-disable */
import CloudFun, { Condition, defineComponent, Operator, ref, watch, reactive } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import SelectBox, { SelectBoxOptions } from '@/cloudfun/components/SelectBox.vue'
import ProformaInvoiceItemTable from "@/components/import-data/ProformaInvoiceItemTable.vue";
import formatDate from 'xe-utils/toDateString'
import { numberToWord, formatAmount, formatResultTotalAmount, formatResultCBMAmount } from '@/libs/utils'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default defineComponent({
  components: {
    Grid,
    SelectBox,
    ProformaInvoiceItemTable,
    loading: VueLoading,
  },
  setup () {
    
    const model = CloudFun.current?.model
    const grid = ref<any>({})
    const isLoading = ref(false);
    var printColumns = [
      { field: 'Name' },
      { field: 'Description' }
    ]
    
    const user = CloudFun.current?.user;
    const policy = CloudFun.current?.policy;
    
    const checkPermissions = (key: string) => {
      try {
        return user && policy?.isApproved(user, key);
      } catch {
        return false;
      }
    };

    const canCreate = checkPermissions("ProformaInvoiceCreate");
    const canUpdate = checkPermissions("ProformaInvoiceUpdate");
    const canDelete = checkPermissions("ProformaInvoiceDelete");

    const gridOptions: GridOptions = {
      stripe: false,
      title: '預估發票管理',
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      canCreate: canCreate,
      canUpdate: canUpdate,
      canDelete: canDelete,
      expand: true,
      multiselect: true,
      printConfig: { sheetName: '預估發票管理清單', columns: printColumns, modes: ['current', 'selected', 'all'] },
      exportConfig: { filename: '預估發票管理清單', type: 'csv', types: ['html', 'csv'], mode: 'all', modes: ['current', 'selected', 'all'], columns: printColumns },
      columns: [
        {
          field: 'ProformaInvoiceNumber',
          title: 'PI No',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
          slots: { default: 'link', }
        },
        {
          field: 'Customer.Number',
          title: '客戶代號',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: 'PIDate',
          title: 'Date',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          formatter: ({ cellValue }) => cellValue ? formatDate(cellValue, "yyyy/MM/dd") : ''
        },
        {
          field: 'Employee.Person.Name',
          title: '負責業務',
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          treeNode: false,
        },
        {
          field: "operate",
          title: "操作",
          align: "center",
          width: 200,
          slots: { default: "operate" }
        }
      ],
      promises: {
        query: model ? (params) => {
          if (params?.sortings?.length === 0) {
            params.sortings.push({ column: 'CreatedTime', order: 1 });
          }
          if(user && !user.IsAdmin) {
            params.condition = new Condition("EmployeeId.Value", Operator.Equal, user.Id).and(params.condition!);
          }
          return model.dispatch('proformaInvoice/query', params)
        } : undefined,
        queryAll: model ? () => model.dispatch('proformaInvoice/query') : undefined,
        save: model ? (params) => model.dispatch('proformaInvoice/save', params) : undefined
      },
      modalConfig: { width: "70%", showFooter: true, fullscreen: true },
      // treeConfig: { children: 'Children' }
    }

    const changeDate = (data: any) => {
      if(data.PIDate) {
        data.TermDelivery = formatDate(addDays(data.PIDate, 60), 'yyyy/MM/dd');
      }
    }

    const formOptions: VxeFormProps = {
      titleWidth: 120,
      titleAlign: 'right',
      items: [
        {
          field: 'ProformaInvoiceNumber',
          title: 'PI No',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'OrderNumber',
          title: '訂單編號',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'CustomerPO',
        //   title: '客戶代號',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'CustomerId',
          title: '客戶',
          span: 12,
          slots: { default: "column-CustomerId" }
        },
        {
          field: 'PIDate',
          title: 'Date',
          span: 12,
          //itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true }, events: { change: (e: any) => changeDate(e.data) } }
          itemRender: { name: '$input', props: { type: 'date', labelFormat: 'yyyy/MM/dd', placeholder: '請輸入日期', clearable: true,readonly: true } }
        },
        // {
        //   field: 'Sales',
        //   title: '負責業務',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'EmployeeId',
          title: '負責業務',
          span: 12,
          slots: { default: "column-employeeId" }
        },        
        {
          field: 'TermDelivery',
          title: '貿易條件',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },               
        {
          field: 'Company',
          title: 'Company',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Address',
          title: 'Address',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Email',
          title: 'Email',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Phone',
          title: '電話號碼',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ATTN',
          title: 'ATTN',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContainerType',
          title: '櫃型',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'ContainerCount',
          title: '貨櫃數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'FinalDestination',
          title: '目的港',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        {
          field: 'Payment',
          title: 'PAYMENT',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },
        // {
        //   field: 'Delivery',
        //   title: 'DELIVERY',
        //   span: 12,
        //   itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        // },
        {
          field: 'LeadTime',
          title: 'LeadTime',
          span: 12,
          itemRender: { name: '$input', props: { placeholder: '請輸入文字', clearable: true }, attrs: { type: 'text' } }
        },        
        { 
          field: "BankInfo",
          title: "銀行資訊", 
          span: 12, 
          itemRender: { name: "$select", options: model ? Object.values(model.enums.BankInfo).map(e => { return { label: e.Name, value: e.Value } }) : [] } },
        {
          field: 'Remark',
          title: '備註',
          span: 24,
          itemRender: { name: '$textarea', props: { autosize: { minRows: 2, maxRows: 4 }, placeholder: '請輸入文字' } }
        },
        {
          title: '項目資料',
          span: 24,
          slots: { default: 'column-ProformaInvoiceItem' }
        },
        {
          field: 'TotleOrderQuantity',
          title: '總下單件數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleQuantity',
          title: '箱數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleRollQuantity',
          title: '總卷數',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'Pallets',
          title: '總棧板數量',
          span: 12,
          itemRender: { name: '$input', props: { type: 'number', placeholder: '請輸入數字' } }
        },
        {
          field: 'TotleCBM',
          title: 'CBM (立方米)',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 4 } }
        },
        {
          field: 'TotleAmount',
          title: '總金額',
          span: 12,          
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          title: '其他費用',
          span: 24,
        },
        {
          field: 'LicenseFee',
          title: '版費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'InspectionFee',
          title: '檢驗費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ToolingFee',
          title: '模具費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'SampleFee',
          title: '樣品費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
        {
          field: 'ShippingFee',
          title: '運費',
          span: 12,
          itemRender: { name: '$input', props: { type: 'float', placeholder: '請輸入數字', digits: 2 } }
        },
      ],
      rules: {
        ProformaInvoiceNumber: [{ required: true }],
        CustomerId: [{ required: true }],
        Email: [{ required: true, message: "Email格式錯誤", pattern: new RegExp("^\\w+((-\\w+)|(\\.\\w+))*\\@[A-Za-z0-9]+((\\.|-)[A-Za-z0-9]+)*\\.[A-Za-z]+$") }],
        Phone: [
          {
            type: "string",
            required: true,
            pattern: new RegExp(
              "^([\\+][0-9]{1,3}([ \\.\\-])?)?([\\(][0-9]{1,6}[\\)])?([0-9 \\.\\-|#]{1,32})(([A-Za-z \\:]{1,11})?[0-9]{1,4}?)$"
            ),
          },
        ],
        ATTN: [{ required: true }],
        TotleOrderQuantity: [{ required: true }],
        PIDate: [{ required: true }],
        TotleAmount: [{ required: true }],
        TotleCBM: [{ required: true }],
        TotleQuantity: [{ required: true }],
        TotleRollQuantity: [{ required: true }],
        Address: [{ required: true }],
        Company: [{ required: true }],
        TermDelivery: [{ required: true }],
        EmployeeId: [{ required: true }],
        OrderNumber: [{ required: true }],
        ContainerType: [{ required: true }],
        ContainerCount: [{ required: true }],
        FinalDestination: [{ required: true }],
        Payment: [{ required: true }],
        Delivery: [{ required: true }],
        Pallets: [{ required: true }],
        LeadTime: [{ required: true }],
        BankInfo: [{ required: true }],
        InspectionFee: [{ required: true }],
        ToolingFee: [{ required: true }],
        SampleFee: [{ required: true }],
        ShippingFee: [{ required: true }],
        LicenseFee: [{ required: true }],
      }
    }

    const itemsTable = ref<any>();
    const formSubmit = (row: any, submit: any) => {
      var items = itemsTable?.value.getData();
      if(items)
        row.Items = items;
      
      submit();
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Name",
      valueField: "Id",
      columns: [
      {
          field: "Number",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Person.Name",
          title: "姓名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        // {
        //   field: "Email",
        //   title: "Email",
        //   showHeaderOverflow: true,
        //   showOverflow: true,
        //   sortable: true
        // }
      ],
      showHeader: true,
      promises: {
        find: (value: any) => model!.dispatch("employee/find", value), // eslint-disable-line
        query: (params: any) => {
          params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.condition!.and("Number", Operator.IsNotNull, null).and(new Condition("Number", Operator.Equal, "C004").or("Number", Operator.Equal, "C003").or("Number", Operator.Equal, "C020").or("Number", Operator.Equal, "C021"));
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return model!.dispatch("employee/query", params) // eslint-disable-line
        }
      }
    };

    const initData = (row: any, callback: any) => {
      row.TotleQuantity = 0;
      row.TotleRollQuantity = 0;
      row.TotleOrderQuantity = 0;
      row.ContainerCount = 0;
      row.Pallets = 0;
      row.TotleCBM = 0;
      row.TotleAmount = 0;
      row.BankInfo = 0;

      row.LicenseFee = 0;
      row.InspectionFee = 0;
      row.ToolingFee = 0;
      row.SampleFee = 0;
      row.ShippingFee = 0;

      row.PIDate = new Date();
      row.TermDelivery = formatDate(addDays(new Date(), 60), 'yyyy/MM/dd');

      row.Items = [] as any;
      callback();
    }

    const ToJobTicke = async (row: any) => {
      if(row.Id) {
        try {
          await model?.dispatch('proformaInvoice/proformaInvoiceToJobTicke', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生工單`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const s2ab = (s: any) => {
      var buf = new ArrayBuffer(s.length);
      var view = new Uint8Array(buf);
      for (var i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
      return buf;
    }

    const exportExcel = async (row: any) => {
      try {
        isLoading.value = true;
        var data = await model!.dispatch('proformaInvoice/exportExcel', row.Id);
        const file = new Blob([s2ab(atob(data))], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const fileURL = URL.createObjectURL(file);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = "預估發票.xlsx";
        link.click();
      } catch (e: any) {
        CloudFun.send("error", {
          subject: "執行失敗",
          content: e,
        });
      } finally {
        isLoading.value = false;
      }
    }

    const customerChange = async (data: any) => {
      if(data?.CustomerId) {
        try {
          const customer = await model!.dispatch('customer/find', data.CustomerId);
          if(customer) {
            data.Address = customer.Address;
            data.ATTN = customer.ATTN;
            data.Email = customer.Email;
            data.Company = customer.Company;
            data.Phone = customer.Phone;
            data.Payment = customer.PaymentTerm;
            data.LeadTime = customer.LeadTime;
            if(customer.EnglishName) {
              data.Company = customer.EnglishName;
            } else data.Company = null;
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        } finally {
          isLoading.value = false;
        }
      }
    }

    const sumItems = (data: any, prop: string) => {
      if(data.length == 0) return 0;
      var num = data.map((e: any) => { return e[prop] ?? 0; }).reduce((a: any, b: any) => parseFloat(a) + parseFloat(b));
      return num;
    };

    const updateTotal = (data: any) => {
      const items = itemsTable?.value.getData();
      if(data && items) {
        data.TotleOrderQuantity = sumItems(items, 'OrderQuantity'); //總下單件數
        data.TotleQuantity = sumItems(items, 'Quantity'); //箱數
        data.TotleRollQuantity = sumItems(items, 'RollQuantity'); //總卷數
        data.TotleCBM = formatResultCBMAmount(sumItems(items, 'CBM')); //CBM (立方米)
        data.TotleAmount = formatResultTotalAmount(sumItems(items, 'TotleAmount')); //總金額
        data.Pallets = sumItems(items, 'Pallets'); //總棧板數量
      }
    }

    const customerSelectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "Number",
      valueField: "Id",
      columns: [
        {
          field: "Number",
          title: "客戶代號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },        
      ],
      showHeader: true,
      promises: {
        find: async (value: any) => await model!.dispatch("customer/find", value), // eslint-disable-line
        query: async (params: any) => {
          // params.condition = new Condition("Status", Operator.Equal, 10).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return await model!.dispatch("customer/query", params) // eslint-disable-line
        }
      }
    };

    const addDays = (date: any, days: any) => {
      const result = new Date(date);
      result.setDate(result.getDate() + days);
      return result;
    }

    const numberToWords = (num: number) => {
      var result = numberToWord(num);
      return result.charAt(0).toUpperCase() + result.slice(1);
    }

    const exportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/PIReportView?piId=${row.Id}`)
    }

    const piExportReport = (row: any) => {
      //替換API_URL
      window.open(`https://report.imp.cloudfun.app/api/ReportView/PIExportReport?piId=${row.Id}`)
    }

    const exportBatchReport = (row: any) => {
      //替換API_URL
      // console.log(selectedRows.value);
      window.open(`https://report.imp.cloudfun.app/api/ReportView/PIReportView?piId=${selectedRows.value}`)
    }

    const batchProformaInvoiceToJobTicke = async (row: any) => {      
      if(selectedRows.value) {
        try {
          await model?.dispatch('proformaInvoice/batchProformaInvoiceToJobTicke', selectedRows.value);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已產生工單`,
          });
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const linkVXEModal = ref<any>();
    const linkModal = reactive({
      visible: false,
      selectedRow: {} as any,
      show(data: any) {
        linkModal.visible = true;
        linkModal.selectedRow = data;
        linkVXEModal.value.maximize()
      },
      async save({ type }: any) {
        if (type >= 0) {
          try {
            await model?.dispatch('documentFlow/deleteAll', { type })
            linkModal.visible = false;
            grid.value.refresh();
          } catch (error: any) {
            CloudFun.send('error', { subject: '執行失敗', content: error })
          }
        } else {
          CloudFun.send('error', { subject: '執行失敗', content: '請確認刪除類型' })
        }
      }
    })

    const proformaInvoiceClone = async (row: any) => {      
      if(row.Id) {
        try {
          await model?.dispatch('proformaInvoice/clone', row.Id);
          CloudFun.send("info", {
            subject: "執行成功",
            content: `已複製預估發票`,
          });
          grid.value.isModalPopup = false;
          grid.value.editingRow = null;
          grid.value.refresh();
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const selectedRows = ref<any[]>([]);
    const onPageChanged = async() => {
      // console.log(selectedRows.value)
      if (selectedRows.value && selectedRows.value.length) {
        const data = grid.value.getData();
        for (const i of selectedRows.value) {
          var exist = data.find((e:any) => e.Id == i);
          if(exist) {
            grid.value.setCheckboxRow(exist, true);
          }
        }
      }
    }

    const onCheckboxChange =  async({ checked, records, reserves, row }: any) => {
      if(checked) {
        selectedRows.value.push(row.Id);
      } else {
        selectedRows.value = selectedRows.value.filter((i:any) => i != row.Id);
      }
      // console.log(selectedRows.value)
    }

    const onCheckboxAll = async({ checked, records, reserves }: any) => {
      if (checked) {
        for (const record of records) {
          const exist = selectedRows.value.find((e) => e === record.Id);
          if (!exist) selectedRows.value.push(record.Id);
        }
      } else {
        const data = grid.value.getData();
        for (const record of data) {
          selectedRows.value = selectedRows.value.filter((i:any) => i != record.Id);
        }
      }
      // console.log(selectedRows.value)
    }

    return {
      grid,
      gridOptions,
      formOptions,
      model,
      selectorOptions,
      initData,
      formSubmit,
      itemsTable,
      ToJobTicke,
      canCreate,
      exportExcel,
      isLoading,
      customerSelectorOptions,
      customerChange,
      updateTotal,
      numberToWords,
      formatAmount,
      exportReport,
      formatDate,
      linkModal,
      linkVXEModal,
      proformaInvoiceClone,
      exportBatchReport,
      onPageChanged,
      selectedRows,
      onCheckboxChange,
      onCheckboxAll,
      batchProformaInvoiceToJobTicke,
      piExportReport
    }
  },
  methods: {
    hideBatchDropDown () {
      cash('#batch-dropdown').dropdown('hide')
    }
  }
})
