
/* eslint-disable */
import SelectBox, { SelectBoxOptions } from "@/cloudfun/components/SelectBox.vue";
import CloudFun, { defineComponent, ref, reactive, PropType, Condition, Operator } from "@cloudfun/core";
import { watch } from "vue";
import { VxeTableProps, VxeFormProps } from "vxe-table";
import { formatAmount, formatTotalAmount, formatCBMAmount } from '@/libs/utils'
import { commafy } from "xe-utils";

export default defineComponent({
  name: "FlowTable",
  components: {
    SelectBox
  },
  props: {
    data: {
      type: Array as PropType<any[]>,
      default: () => []
    },
    aid: Number,
    customerId: Number,
    visible: Boolean,
    readType: Boolean,
  },
  setup(props, { emit }) {
    const model = CloudFun.current?.model;
    const grid = ref<any>();
    const gridOptions = {
      props: {
        sortConfig: {
          multiple: true,
          defaultSort: [{ field: 'Ordinal', order: 'asc' }]
        }
      } as VxeTableProps,
      insert() {
        const { row } = grid.value.insertAt({}, 0);
        grid.value.setActiveRow(row)
      }
    }

    const table = reactive({
      data: [] as any[],
      isLoading: false,
      isEditing: false,
      formData: {  } as any,
      selectedRow: null as any | null,
      // insert() {
      //   table.formData = { OutSource: false, Fee: 0, PayStatus: 0, Ordinal: 0 };
      //   table.selectedRow = null;
      //   table.isEditing = true;
      // },
      update(row: any) {
        // console.log(row)
        table.formData = { ...row };
        table.selectedRow = row;
        table.isEditing = true;
      },
      delete(row: any) {
        grid.value.remove(row);
        table.reset();

        emit('updateTotal');
      },
      submit() {
        emit('submit', grid.value.getTableData().fullData)
      },
      reset() {
        table.formData = {};
        table.formData.OrderQuantity = 0;
        table.formData.Radix = 0;
        table.formData.Quantity = 0;
        table.formData.RollQuantity = 0;
        table.formData.UnitPrice = 0;
        table.formData.TotleAmount = 0;
        // table.formData.GW = 0;
        table.formData.CBM = 0;
        // table.formData.Long = 0;
        // table.formData.Width = 0;
        // table.formData.Thickness = 0;
        table.formData.Pallets = 0;
        table.formData.Ordinal = 0;
        table.formData.ThicknessDisplay = false;
        table.selectedRow = null;
        table.isEditing = false;
      },
      async save() {
        // table.formData.ProductDescription = `${table.formData.ProductType ?? "無"},${table.formData.AdhesiveType ?? "無"},${table.formData.ColorName ?? "無"}`
        // table.formData.ProductSize = `${table.formData.Long ? Number(table.formData.Long) : 0},${table.formData.Width ? Number(table.formData.Width) : 0},${table.formData.Thickness ? Number(table.formData.Thickness) : 0}`
        table.formData.Product = null;
        if(!table.formData.ProductId)
          return CloudFun.send('error', { subject: '執行失敗', content: '請選擇產品' })
        if (table.formData.ProductId) {
          const temp = await model?.dispatch("product/find", table.formData.ProductId)
          table.formData.Product = { Id: temp.Id, ProductName: temp.ProductName, ProductNo: temp.ProductNo };
        }
        if(table.isEditing) {
          // 編輯確認
          Object.assign(table.selectedRow, table.formData);
        } else {
          // 新增確認
          table.formData.Ordinal = getNextOrdinal();
          await grid.value.insert(table.formData);
        }
        table.reset();
        // grid.value.getTableData().fullData.sort((a: any, b: any) => b.time - a.time);
        await grid.value.sort("Ordinal", 'asc');
        emit('updateTotal');
      },
      rules: {
        ProductId: [{ required: true }],
        // ProductName: [{ required: true }],
      }
    })

    const getNextOrdinal = () => {
      const data = grid.value.getTableData().fullData;
      if(!data || data.length == 0) return 1; 
      var maxOrdinal = Math.max(...data.map((e: any) => e.Ordinal));
      return (maxOrdinal ?? 0) + 1;
    }

    watch(() => props.aid, async(value) => {
      if(value) {
        // console.log(props.data)
        var t = Date.now();
        if(props.data && props.data.length > 0) {         
          table.data = props.data;
        } else {
          table.data = [] as any;
        }
        table.reset();        
      } else {
        table.data = [] as any;
        table.reset();
      }
    }, { immediate: true });

    watch(() => table.formData.UnitPrice, async(value) => {      
      //table.formData.TotleAmount = formatCeil(((value ?? 0) * (table.formData.OrderQuantity ?? 0)).toFixed(3), 100);
      var result = formatTotalAmount((value ?? 0), (table.formData.OrderQuantity ?? 0), 1000)
      table.formData.TotleAmount = result;
    }, { immediate: true });

    watch(() => table.formData.OrderQuantity, async(value) => {
      // console.log('OrderQuantity')      
      //table.formData.TotleAmount = formatCeil(((table.formData.UnitPrice ?? 0) * (value ?? 0)).toFixed(3), 100);
      var result = formatTotalAmount((table.formData.UnitPrice ?? 0), (value ?? 0), 1000);
      table.formData.TotleAmount = result;  
      if(value && table.formData.Radix){
        table.formData.Quantity = value / table.formData.Radix;
      }
    }, { immediate: true });

    watch(() => table.formData.Quantity, async(value) => {
      table.formData.OrderQuantity = value * table.formData.Radix; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Quantity')
          table.formData.RollQuantity = value * table.formData.Radix * temp.Bundle;
          table.formData.CBM = formatCBMAmount(temp.CBM, (value ?? 0));
        }
      }
    }, { immediate: true });

    watch(() => table.formData.Radix, async(value) => {
      table.formData.OrderQuantity = table.formData.Quantity * value; // Radix已不需要從product取得
      if(table.formData.ProductId) {
        const temp = await getProductDetails(table.formData.ProductId);
        if (temp) {
          // console.log('Radix')
          table.formData.RollQuantity = table.formData.Quantity * value * temp.Bundle;
        }
      }
    }, { immediate: true });

    const getProductDetails = async (productId: any) => {
      const temp = await model?.dispatch("product/find", productId)
      return temp ? temp : null;
    }

    const anyForm = ref<any>();

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇",
      textField: "ProductName",
      valueField: "Id",
      columns: [
        {
          field: "ProductNo",
          title: "品號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "SKU",
          title: "SKU",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductName",
          title: "品名",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "ProductSize",
          title: "規格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("product/find", value),
        query: params => {
          if(props.customerId) params.condition = new Condition("CustomerId", Operator.Equal, props.customerId).and(params.condition!);
          return model!.dispatch("product/query", params)
        }
      }
    };

    const customerProduct = async (data: any) => {
      if(data?.ProductId) {
        try {
          const temp = await model?.dispatch("product/find", data.ProductId);
          
          var condition = new Condition("Items.ProductId", Operator.Equal, data.ProductId);
          var sortings = [{ column: 'CreatedTime', order: 1 }];
          const PiList = await model?.dispatch("proformaInvoice/query", { condition, sortings });
          //console.log(PiList)
          
          if (temp) {
            //10.16 抓最後一張PI的產品單價
            var resultPrice = temp.UnitPrice;
            // if(PiList && PiList.length) {
            //   var lastPiItem = PiList[0]?.Items.find((e: any) => e.ProductId == data.ProductId);
            //   //console.log(lastPiItem)
            //   if(lastPiItem)
            //     resultPrice = lastPiItem.UnitPrice;
            // }
            // 下單件數 = 箱數 * 產品的radix
            // CBM = 產品的CBM * 下單件數 (產品無CBM則不計算)
            // ProformaInvoiceItem.OrderQuantity = ProformaInvoiceItem.Quantity * Product.Radix
            // 總金額 = 下單件數(幾卷) * 單位價格
            // ProformaInvoiceItem.TotleAmount = ProformaInvoiceItem.OrderQuantity * ProformaInvoiceItem.UnitPrice
            // 總卷數 = 箱數 * 套/箱 * 卷/套
            data.UnitPrice = resultPrice; //temp.UnitPrice;
            data.Radix = temp.Radix;
            // data.OrderQuantity = data.Quantity * temp.Radix;
            // data.RollQuantity = data.Quantity * temp.Radix * temp.Bundle;
            // data.CBM = temp.CBM * data.OrderQuantity;
            if(data.Quantity && data.Quantity > 0) data.CBM = formatCBMAmount(temp.CBM, (data.Quantity ?? 0));
          }
        } catch (e: any) {
          CloudFun.send("error", {
            subject: "執行失敗",
            content: e,
          });
        }
      }
    }

    const TF = [{ label: "是", value: true }, { label: "否", value: false }];

    return {
      table,
      grid,
      gridOptions,
      anyForm,
      selectorOptions,
      model,
      TF,
      customerProduct,
      formatAmount,
      commafy,
    }
  },
  methods: {
    getData() {
      return this.grid.getTableData().fullData;
    }
  }
})
